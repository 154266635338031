import * as requests from '@/api/requests.js';

export default {
	namespaced: true,
	state: {
		deal:{
			id:null,
			company_id:null, 
			client:{
				company_name:null,
				name:null,
				phone:null,
				email:null,
			},
			manager:{
				name:null,
				phone:"+7 (812) 213-35-55",
				email:"zakaz@pzn.su",
			}
		},
		file_sketch:null,
		sketch:null, 
		products_all:null,
		cloths_all:null,
		сornice_all:null,
		profile_all:null,

		insurance:0,
		insurance_tax_before:0, 
		insurance_tax_after:0, 
		extra_charge_products:0,
		extra_charge_mechanics:0,
		extra_charge_delivery:0,
		extra_charge_montage:0, 
		osno:0,
		osno_nds:0,
		usn:0,
		usn_nds:0,
		  
		montage_quantity_job:null, //Количество рабочих
		montage_price_job_day:null, //Стоимость рабочего дня
		montage_stairs_rental:null, //Стоимость аренды лестницы
		montage_rental_scaffolding:null, //Стоимость аренды стр.лесов
		montage_price_consumables_before_7:null, //Стоимость расходников до 7 м
		montage_price_consumables_after_7:null, //Стоимость расходников свыше 7 м.	 
		montage_1_day:null, //1 рабочий день от веса изделий, до __кг
		montage_2_day:null, //2 рабочий день от веса изделий, до __кг.
		montage_koef_mechanick:null, //Повышающий коэфф. При наличии механики 	1,5  
		calculations:{
			date:null,
			tax:{
				usn:true,
				nds:false,
				not_nds:false
			},
			kp_name:null,
			products:null,
			mechanics:null, 
			total_weight:0,
			koef_weight_1:0,			  
			total_products_cost_price:0, 
			total_products_tax_before:0,
			total_products_tax_after:0,
			view_total_products_price_all:0, 
			carnise_profile_cost_price:0,
			carnise_profile_tax_before:0,
			carnise_profile_tax_after:0,
			carnise_profile_koef_weight_1:0,			 
			carnise_profile_koef_weight_block_mechanics_1:0,
			view_carnise_profile_total_price_1:0, 
			view_carnise_profile_price_all:0, 
			total_mechanics_cost_price:0,
			total_mechanics_tax_before:0,
			total_mechanics_tax_after:0,			 
			view_total_mechanics_price_all:0, 
			total_price:0,
			total_price_tax_before:0,
			total_price_tax_after:0, 
			delivery:{
				status:false,				
				status_delline:false,
				status_custom:false,
				address:null,
				rec_address:null,
				rec_price:0,
				price:0, 
				price_tax_before:0, 
				price_tax_after:0, 
				view_total_price_1:0,
				koef_weight_1:0,
			},
			montage:{
				status:false,
				rec_price:0,
				price:0, 
				price_tax_before:0, 
				price_tax_after:0, 
				view_total_price_1:0,
				koef_weight_1:0,

				height:0,
				width:0,
				weight:0,

			},
			delivery_montage_box:false,
			view_total_price_services:0,
		}
	},
	getters: {
		kpComputed:state=>{
			return state
		},  
	},
	mutations: {    
		setAddress(state, value){
			state.calculations.delivery.address = value;
		},
		setDeliveryVal(state, price){
			state.calculations.delivery.price = price;
		},
		setMontageVal(state, price){
			state.calculations.montage.price = price;
		},
		setCurrentDate(state, date){
			state.calculations.date = date;
		},
		setSketch(state, sketch){  
			state.sketch = sketch;
			state.file_sketch = null;
		},
		setCompany(state, items){  
			state.calculations.delivery.rec_address = items.address;
			state.deal.company_id = items.company_id;
			state.deal.client.company_name = items.company_name; 
			state.deal.client.phone = items.phone;
			state.deal.client.email = items.email; 
		},
		setClientName(state, name){ 
			state.deal.client.name = name; 
		},
		setManager(state, items){  
			state.deal.manager.name = items.name; 
		},
		setDealId(state, id){  
			state.deal.id = id;
		},
		setTax(state, id){  
			if(id == "tax_usn") state.calculations.tax.nds = false;
			if(id == "tax_nds") state.calculations.tax.usn = false;
		},
		setList(state, data){  
			state.products_all = data.products_all; 
			state.cloths_all = data.cloths_all; 
			state.сornice_all = data.сornice_all;
			state.profile_all = data.profile_all;

			state.insurance = data.extra_charge.insurance; 
			state.extra_charge_products = data.extra_charge.products;
			state.extra_charge_mechanics = data.extra_charge.mechanics;
			state.extra_charge_delivery = data.extra_charge.delivery;
			state.extra_charge_montage = data.extra_charge.montage;
			state.osno = data.extra_charge.osno;
			state.osno_nds = data.extra_charge.osno_nds;
			state.usn = data.extra_charge.usn;
			state.usn_nds = data.extra_charge.usn_nds;
 
			state.montage_quantity_job = Number(data.montage_const.montage_quantity_job); 	
			state.montage_price_job_day = Number(data.montage_const.montage_price_job_day); 	
			state.montage_stairs_rental = Number(data.montage_const.montage_stairs_rental); 	
			state.montage_rental_scaffolding = Number(data.montage_const.montage_rental_scaffolding); 	  
			state.montage_price_consumables_before_7 = Number(data.montage_const.montage_price_consumables_before_7); 	
			state.montage_price_consumables_after_7 = Number(data.montage_const.montage_price_consumables_after_7); 	
			state.montage_1_day = Number(data.montage_const.montage_1_day); 	 
			state.montage_2_day = Number(data.montage_const.montage_2_day); 	 
			state.montage_koef_mechanick = Number(data.montage_const.montage_koef_mechanick); 	 
		},
		setData(state, data){ 
			if(data !== null) state.calculations = data;
		},
		setProduct(state, product){  
			if(state.calculations.products == null) state.calculations.products = [product];
			else state.calculations.products.push(product);
		}, 
		spliceProduct(state, key){  
			state.calculations.products.splice(key, 1);
			if(state.calculations.products.length == 0) {
				state.calculations.products = null;
				state.calculations.total_products_cost_price = 0; 
				state.calculations.total_products_tax_before = 0;
				state.calculations.total_products_tax_after = 0;
				state.calculations.view_total_products_price_all = 0;	
			}
		},
		setMechanics(state, mechanic){  
			if(mechanic == null) state.calculations.mechanics = mechanic;
			else{  
				if(state.calculations.mechanics == null) state.calculations.mechanics = [mechanic];
				else state.calculations.mechanics.push(mechanic);
			}
		}, 
		spliceMechanics(state, key){  
			state.calculations.mechanics.splice(key, 1);
			if(state.calculations.mechanics.length == 0) {
				state.calculations.mechanics = null;
				state.calculations.total_mechanics_cost_price = 0; 
				state.calculations.total_mechanics_tax_before = 0;
				state.calculations.total_mechanics_tax_after = 0;
				state.calculations.view_total_mechanics_price_all = 0;				 
			}
		},
		setTotalProductPg(state, {key, total_product_pg}){ 
			state.calculations.products[key].total_product_pg = total_product_pg;
		},
		setTotalLiningPg(state, {key, total_lining_pg}){ 
			state.calculations.products[key].total_lining_pg = total_lining_pg;
		},
		setTotalProductPrice(state, {key, total_price}){   
			state.calculations.products[key].total_price = total_price;  
		}, 
		setWeight(state, {key, weight}){ 
			if(key !== null) state.calculations.products[key].weight = weight; 
			let total_weight = 0; 
			if(state.calculations.products !== null){ 
				for(let key in state.calculations.products)
					total_weight = total_weight + state.calculations.products[key].weight;   
			}
			state.calculations.total_weight = Math.round(total_weight);
			state.calculations.montage.weight = Math.round(total_weight);
		},
		setJobProductPrice(state, {key, job_price_total}){ 
			state.calculations.products[key].job_price = job_price_total;
		}, 
		mechanicNullable(state, key){  
			state.calculations.mechanics[key].product = null;
			state.calculations.mechanics[key].product_key = null; 
			state.calculations.mechanics[key].height = 0;
			state.calculations.mechanics[key].length = 0;
			state.calculations.mechanics[key].quantity = 0;
			state.calculations.mechanics[key].weight = 0;
			state.calculations.mechanics[key].total = 0; 
		},
		setTotalMechanic(state, { key, total_mechanic }){  
			state.calculations.mechanics[key].total = total_mechanic;  
		},  
		setTotalPrice(state){
			// I
			// себестоимость изделий
			let total_products_cost_price = 0; 
			if(state.calculations.products !== null){ 
				for(let key in state.calculations.products)  
					total_products_cost_price = total_products_cost_price + state.calculations.products[key].total_price; 				 
			}

			state.calculations.total_products_cost_price = total_products_cost_price;		
			console.log("ОС сс - "+total_products_cost_price);
			
			//себестоимость профилей 
			let carnise_profile_cost_price = 0;
			let carnise_profile_quantity = 0;
			if(state.calculations.mechanics !== null) {
				for(let key in state.calculations.mechanics){ 
					if(state.calculations.mechanics[key].mechanic !== null && state.calculations.mechanics[key].mechanic.type == 'profile'){
						if(state.calculations.mechanics[key].product == null) carnise_profile_quantity = state.calculations.mechanics[key].length; 
						else {
							let product_key = state.calculations.mechanics[key].product.key;
							carnise_profile_quantity = state.calculations.products[product_key].width * state.calculations.products[product_key].quantity;	 
						} 	
						carnise_profile_cost_price = carnise_profile_cost_price + Number(state.calculations.mechanics[key].mechanic.price)*Number(carnise_profile_quantity); 	
					}
				}	 				   
			}

			state.calculations.carnise_profile_cost_price = carnise_profile_cost_price;

			//сумма блока механика
			let total_mechanics_cost_price = state.calculations.carnise_profile_cost_price;

			if(state.calculations.mechanics !== null){  
				for(let key in state.calculations.mechanics){ 
					total_mechanics_cost_price = total_mechanics_cost_price + state.calculations.mechanics[key].total*state.calculations.mechanics[key].quantity; 
				} 
			}
			state.calculations.total_mechanics_cost_price = total_mechanics_cost_price;		

			console.log("мех сс - "+state.calculations.total_mechanics_cost_price);
				
			// доставка, монтаж
			if(total_products_cost_price !==0 || total_mechanics_cost_price !==0){
				 			 
				//нет доставки
				if(state.calculations.delivery.price == 0) state.calculations.montage.rec_price = Number(Math.round((total_products_cost_price+total_mechanics_cost_price)*state.extra_charge_montage/100));
				else { //есть доставка 

						let result = state.montage_quantity_job*state.montage_price_job_day;
						let result_stairs = Number(state.calculations.montage.height) <=4 ? state.montage_stairs_rental : state.montage_rental_scaffolding;							
						let result_day = 0;
						if(Number(state.calculations.montage.weight)<=state.montage_1_day) result_day = 1;
						else if(Number(state.calculations.montage.weight)<=state.montage_2_day) result_day = 2;
							else result_day = 3;
						let result_consumables = Number(state.calculations.montage.width) <=7 ? state.montage_price_consumables_before_7 : state.montage_price_consumables_after_7;						

						result = ((result+result_stairs)*result_day)+result_consumables + Number(state.calculations.delivery.price);

						if(state.calculations.mechanics !== null) result = result*state.montage_koef_mechanick;   

						result = result+result*state.extra_charge_montage/100

						state.calculations.montage.rec_price = result; 			
				}
			}

			
			//II
			// до налога
			let total_products_tax_before = 0;

			if(state.calculations.products !== null){
				for(let key in state.calculations.products){
					let total_price = state.calculations.products[key].total_price; 
					let tax_before = total_price+((total_price*state.extra_charge_products)/100);
					total_products_tax_before = total_products_tax_before + tax_before;
					state.calculations.products[key].tax_before = tax_before;
				}    
			}
			state.calculations.total_products_tax_before = total_products_tax_before;
			console.log("ос до налога "+total_products_tax_before)

			//////////
			state.calculations.carnise_profile_tax_before = state.calculations.carnise_profile_cost_price+(state.calculations.carnise_profile_cost_price*state.extra_charge_mechanics/100);
			/////////

			let total_mechanics_tax_before = 0;

			if(state.calculations.mechanics !== null){  
				for(let key in state.calculations.mechanics){		 
					let total_price = state.calculations.mechanics[key].total; 
					let tax_before = total_price+((total_price*state.extra_charge_mechanics)/100);
					total_mechanics_tax_before = total_mechanics_tax_before + tax_before;
					state.calculations.mechanics[key].tax_before = tax_before;
				}
			}
			state.calculations.total_mechanics_tax_before = total_mechanics_tax_before + state.calculations.carnise_profile_tax_before;
			 
			state.insurance_tax_before = state.insurance+((state.insurance*state.extra_charge_products)/100);
			
			let delivery_price = 0;
			let montage_price = 0;
			 
			if(state.calculations.delivery.status) delivery_price = Number(state.calculations.delivery.price);
			if(state.calculations.montage.status) montage_price = Number(state.calculations.montage.price);
			  
			state.calculations.delivery.price_tax_before = delivery_price+(delivery_price*state.extra_charge_delivery/100);
			state.calculations.montage.price_tax_before = montage_price+(montage_price*state.extra_charge_montage/100);
 
			state.calculations.total_price_tax_before = state.calculations.delivery.price_tax_before +
				state.calculations.montage.price_tax_before +
				state.insurance_tax_before +
				state.calculations.total_mechanics_tax_before +
				state.calculations.total_products_tax_before; 
 

			//III
			// после налога
			//УСН
			if(state.calculations.tax.usn && (total_products_cost_price !==0 || total_mechanics_cost_price !==0)) { 	

				let total_products_tax_after = 0;

				if(state.calculations.products !== null){
					
					for(let key in state.calculations.products){
						let tax_before = state.calculations.products[key].tax_before; 
						let tax_after = (tax_before+((tax_before/(100-state.usn))*state.usn))*(1+state.usn_nds/100);
						total_products_tax_after = total_products_tax_after + tax_after;
						state.calculations.products[key].tax_after = tax_after;
					}    
				}
				state.calculations.total_products_tax_after = total_products_tax_after;

				/////   
				state.calculations.carnise_profile_tax_after = (state.calculations.carnise_profile_tax_before + (state.calculations.carnise_profile_tax_before/(100-state.usn)*state.usn))*(1+state.usn_nds/100);
				/////

				let total_mechanics_tax_after = state.calculations.carnise_profile_tax_after;

				if(state.calculations.mechanics !== null){  
					for(let key in state.calculations.mechanics){		 
						let tax_before = state.calculations.mechanics[key].tax_before;  
						let tax_after = ((tax_before+((tax_before/(100-state.usn))*state.usn))*(1+state.usn_nds/100))*state.calculations.mechanics[key].quantity;
						total_mechanics_tax_after = total_mechanics_tax_after + tax_after;
						state.calculations.mechanics[key].tax_after = Math.round(tax_after);
					}
				}
				state.calculations.total_mechanics_tax_after = total_mechanics_tax_after;
 
				state.insurance_tax_after = (state.insurance_tax_before+((state.insurance_tax_before/(100-state.usn))*state.usn)*(1+state.usn_nds/100));
				state.calculations.delivery.price_tax_after = (state.calculations.delivery.price_tax_before+((state.calculations.delivery.price_tax_before/(100-state.usn))*state.usn))*(1+state.usn_nds/100);
				state.calculations.montage.price_tax_after = (state.calculations.montage.price_tax_before+((state.calculations.montage.price_tax_before/(100-state.usn))*state.usn))*(1+state.usn_nds/100);
							 
				state.calculations.total_price_tax_after = state.calculations.delivery.price_tax_after +
					state.calculations.montage.price_tax_after +
					state.insurance_tax_after +
					state.calculations.total_mechanics_tax_after +
					state.calculations.total_products_tax_after;  
			}

			//НДС

			if(state.calculations.tax.nds && (total_products_cost_price !==0 || total_mechanics_cost_price !==0)) { 	

				let total_products_tax_after = 0;

				if(state.calculations.products !== null){
					for(let key in state.calculations.products){
						let tax_before = state.calculations.products[key].tax_before; 
						let total_price = state.calculations.products[key].total_price; 
						let tax_after = (tax_before + (tax_before - total_price)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);
						total_products_tax_after = total_products_tax_after + tax_after;
						state.calculations.products[key].tax_after = tax_after;
					}    
				}
				state.calculations.total_products_tax_after = total_products_tax_after;

				/////    
				state.calculations.carnise_profile_tax_after = (state.calculations.carnise_profile_tax_before + (state.calculations.carnise_profile_tax_before - state.calculations.carnise_profile_cost_price)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);
				/////			
				
				let total_mechanics_tax_after = state.calculations.carnise_profile_tax_after;

				if(state.calculations.mechanics !== null){  
					 
					for(let key in state.calculations.mechanics){		 
						let tax_before = state.calculations.mechanics[key].tax_before;  
						let total_price = state.calculations.mechanics[key].total; 
						let tax_after = (tax_before + (tax_before - total_price)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);					
						total_mechanics_tax_after = total_mechanics_tax_after + tax_after;
						state.calculations.mechanics[key].tax_after = tax_after;
					}
				}
				state.calculations.total_mechanics_tax_after = total_mechanics_tax_after;		 
 
				state.insurance_tax_after = (state.insurance_tax_before + (state.insurance_tax_before - state.insurance)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);					
				
				state.calculations.delivery.price_tax_after = (state.calculations.delivery.price_tax_before + (state.calculations.delivery.price_tax_before - delivery_price)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);
				state.calculations.montage.price_tax_after = (state.calculations.montage.price_tax_before + (state.calculations.montage.price_tax_before - montage_price)/(100-state.osno)*state.osno)*(1+state.osno_nds/100);
				 
				state.calculations.total_price_tax_after = state.calculations.delivery.price_tax_after +
					state.calculations.montage.price_tax_after +
					state.insurance_tax_after +
					state.calculations.total_mechanics_tax_after +
					state.calculations.total_products_tax_after;  
			}

			//IV
			//веса
			if(state.calculations.delivery_montage_box){
				//вынос отдельным блоком
				state.calculations.koef_weight_1 = state.calculations.total_mechanics_tax_after + 
				state.calculations.total_products_tax_after +
				state.calculations.delivery.price_tax_after +
				state.calculations.montage.price_tax_after;  
 
				if(state.calculations.products !== null){
					for(let key in state.calculations.products){ 
						state.calculations.products[key].koef_weight_1  = state.calculations.products[key].tax_after/state.calculations.koef_weight_1;  
						state.calculations.products[key].koef_weight_block_product_1 = state.calculations.products[key].tax_after/state.calculations.total_products_tax_after; 
					}
				}
				 
				////////////
				if(state.calculations.mechanics !== null){ 
					state.calculations.carnise_profile_koef_weight_1 = state.calculations.carnise_profile_tax_after/state.calculations.koef_weight_1;
					state.calculations.carnise_profile_koef_weight_block_mechanics_1 = state.calculations.carnise_profile_tax_after/state.calculations.total_mechanics_tax_after;
				}
				///////////

				if(state.calculations.mechanics !== null){
					for(let key in state.calculations.mechanics){ 
						state.calculations.mechanics[key].koef_weight_1  = state.calculations.mechanics[key].tax_after/state.calculations.koef_weight_1;  						 
						state.calculations.mechanics[key].koef_weight_block_mechanics_1 = state.calculations.mechanics[key].tax_after/state.calculations.total_mechanics_tax_after; 
					}	 
				}  

				state.calculations.delivery.koef_weight_1 = state.calculations.delivery.price_tax_after/state.calculations.koef_weight_1;
				state.calculations.montage.koef_weight_1 = state.calculations.montage.price_tax_after/state.calculations.koef_weight_1;
 
				//V

				let view_total_mechanics_price_all = 0;
				let view_total_products_price_all = 0;

				if(state.calculations.products !== null){
					for(let key in state.calculations.products){ 

						state.calculations.products[key].view_total_price_all = Math.ceil(state.calculations.products[key].tax_after + 
							state.insurance_tax_after *
							state.calculations.products[key].koef_weight_block_product_1);
							 
						state.calculations.products[key].view_total_price_1 = Math.round(state.calculations.products[key].view_total_price_all/state.calculations.products[key].quantity);  

						view_total_products_price_all = view_total_products_price_all + state.calculations.products[key].view_total_price_all;
					}
					state.calculations.view_total_products_price_all = view_total_products_price_all;
				}

				if(state.calculations.mechanics !== null){		
						
					let total_carnise_profile_1 = 0;

					if(state.calculations.products == null)
						total_carnise_profile_1 = state.calculations.carnise_profile_tax_after+(state.insurance_tax_after*state.calculations.carnise_profile_koef_weight_1);
					else total_carnise_profile_1 = state.calculations.carnise_profile_tax_after;

					state.calculations.view_carnise_profile_price_all = Math.round(total_carnise_profile_1); 								 
					state.calculations.view_carnise_profile_total_price_1 = Math.round(total_carnise_profile_1);
  

					for(let key in state.calculations.mechanics){ 

						let total_1 = 0;
						if(state.calculations.products == null)
							total_1 = state.calculations.mechanics[key].tax_after+(state.insurance_tax_after*state.calculations.mechanics[key].koef_weight_1);  
						 
						else total_1 = state.calculations.mechanics[key].tax_after;
 
						state.calculations.mechanics[key].view_total_price_all = Math.round(total_1); 	
							 
						state.calculations.mechanics[key].view_total_price_1 = Math.round(state.calculations.mechanics[key].view_total_price_all/state.calculations.mechanics[key].quantity); 

						view_total_mechanics_price_all = view_total_mechanics_price_all + state.calculations.mechanics[key].view_total_price_all;	 						 
					}	 

					state.calculations.view_total_mechanics_price_all = view_total_mechanics_price_all + state.calculations.view_carnise_profile_price_all; 
				}  
				 
				if(state.calculations.products == null) state.calculations.montage.view_total_price_1 = Math.round(state.calculations.montage.price_tax_after+state.insurance_tax_after*state.calculations.montage.koef_weight_1);
				else state.calculations.montage.view_total_price_1 = Math.round(state.calculations.montage.price_tax_after);

				if(state.calculations.products == null) state.calculations.delivery.view_total_price_1 = Math.round(state.calculations.delivery.price_tax_after+state.insurance_tax_after*state.calculations.delivery.koef_weight_1);
				else state.calculations.delivery.view_total_price_1 = Math.round(state.calculations.delivery.price_tax_after);

				state.calculations.view_total_price_services = state.calculations.montage.view_total_price_1+state.calculations.delivery.view_total_price_1;
 
				state.calculations.total_price = view_total_products_price_all+state.calculations.view_total_mechanics_price_all+state.calculations.montage.view_total_price_1+state.calculations.delivery.view_total_price_1;
  
			}
			else{

				state.calculations.koef_weight_1 = state.calculations.total_mechanics_tax_after + state.calculations.total_products_tax_after;  
	
				if(state.calculations.products !== null){
					for(let key in state.calculations.products){ 
						state.calculations.products[key].koef_weight_1  = state.calculations.products[key].tax_after/state.calculations.koef_weight_1;  
						state.calculations.products[key].koef_weight_block_product_1 = state.calculations.products[key].tax_after/state.calculations.total_products_tax_after; 
					}
				}

				////////////
				if(state.calculations.mechanics !== null){ 
					state.calculations.carnise_profile_koef_weight_1 = state.calculations.carnise_profile_tax_after/state.calculations.koef_weight_1;
					state.calculations.carnise_profile_koef_weight_block_mechanics_1 = state.calculations.carnise_profile_tax_after/state.calculations.total_mechanics_tax_after;
				}
				////////////

				if(state.calculations.mechanics !== null){
					for(let key in state.calculations.mechanics){ 
						state.calculations.mechanics[key].koef_weight_1  = state.calculations.mechanics[key].tax_after/state.calculations.koef_weight_1;  						 
						state.calculations.mechanics[key].koef_weight_block_mechanics_1 = state.calculations.mechanics[key].tax_after/state.calculations.total_mechanics_tax_after; 
					}	 
				}  
			  
				//V
				let view_total_mechanics_price_all = 0;
				let view_total_products_price_all = 0;

				if(state.calculations.products !== null){
					for(let key in state.calculations.products){ 

						state.calculations.products[key].view_total_price_all = Math.ceil(state.calculations.products[key].tax_after + 
							state.insurance_tax_after *
							state.calculations.products[key].koef_weight_block_product_1 +
							(state.calculations.delivery.price_tax_after + state.calculations.montage.price_tax_after)*
							state.calculations.products[key].koef_weight_1);
							 
						state.calculations.products[key].view_total_price_1 = Math.round(state.calculations.products[key].view_total_price_all/state.calculations.products[key].quantity);  

						view_total_products_price_all = view_total_products_price_all + state.calculations.products[key].view_total_price_all;
					}
					state.calculations.view_total_products_price_all = view_total_products_price_all;
				}
				  
				if(state.calculations.mechanics !== null){

					////////////////
					let total_carnise_profile_1 = 0;
					if(state.calculations.products == null)
						total_carnise_profile_1 = state.calculations.carnise_profile_tax_after+(state.insurance_tax_after*state.calculations.carnise_profile_koef_weight_1);
					else total_carnise_profile_1 = state.calculations.carnise_profile_tax_after;

					state.calculations.view_carnise_profile_price_all = Math.round(total_carnise_profile_1 + 
						(state.calculations.delivery.price_tax_after + state.calculations.montage.price_tax_after)*
						state.calculations.carnise_profile_koef_weight_1);					
					
					state.calculations.view_carnise_profile_total_price_1 = state.calculations.view_carnise_profile_price_all;
					////////////////	

					for(let key in state.calculations.mechanics){ 

						let total_1 = 0;
						if(state.calculations.products == null)
							total_1 = state.calculations.mechanics[key].tax_after+(state.insurance_tax_after*state.calculations.mechanics[key].koef_weight_1);  
						 
						else total_1 = state.calculations.mechanics[key].tax_after;
 
						state.calculations.mechanics[key].view_total_price_all = Math.round(total_1 +
							(state.calculations.delivery.price_tax_after + state.calculations.montage.price_tax_after)* 
							state.calculations.mechanics[key].koef_weight_1); 	
							 
						state.calculations.mechanics[key].view_total_price_1 = Math.round(state.calculations.mechanics[key].view_total_price_all/state.calculations.mechanics[key].quantity); 

						view_total_mechanics_price_all = view_total_mechanics_price_all + state.calculations.mechanics[key].view_total_price_all;	 						 
					}	 

					state.calculations.view_total_mechanics_price_all = view_total_mechanics_price_all+state.calculations.view_carnise_profile_price_all; 
				}  
 
				state.calculations.total_price = view_total_products_price_all+state.calculations.view_total_mechanics_price_all;
			}

			//коррекция погрешности окургления 
 
			if(state.calculations.products !== null)
				for(let key in state.calculations.products)
					if(state.calculations.products[key].view_total_price_1 !== 0){
						let view_total_price_all = state.calculations.products[key].view_total_price_1*state.calculations.products[key].quantity;
						let incriment = view_total_price_all - state.calculations.products[key].view_total_price_all;
						state.calculations.products[key].view_total_price_all = view_total_price_all;
						state.calculations.view_total_products_price_all = state.calculations.view_total_products_price_all + incriment;
						state.calculations.total_price = state.calculations.total_price + incriment;
					}

			if(state.calculations.mechanics !== null)
				for(let key in state.calculations.mechanics)
					if(state.calculations.mechanics[key].view_total_price_1 !== 0){
						let view_total_price_all = state.calculations.mechanics[key].view_total_price_1*state.calculations.mechanics[key].quantity;
						let incriment = view_total_price_all - state.calculations.mechanics[key].view_total_price_all;
						state.calculations.mechanics[key].view_total_price_all = view_total_price_all;
						state.calculations.view_total_mechanics_price_all = state.calculations.view_total_mechanics_price_all + incriment;
						state.calculations.total_price = state.calculations.total_price + incriment;
					} 

		} 
	},
	actions: {
		async delSketch({ commit, state }){
			await requests.sketchDel({deal_id:state.deal.id});
			commit('setSketch', null);
		},
		async handleQr({ commit, state }, file){			 
			let formData = new FormData(); 
			formData.append('file', file);
			formData.append('deal_id', state.deal.id);
			let res = await requests.sketchSave(formData);
			if(res.result) commit('setSketch', res.sketch);
		},
		setManager({ commit }, data){
			commit('setManager', data);
		},
		setCompany({ commit }, data){
			commit('setCompany', data);
		},
		setClientName({ commit }, name){
			commit('setClientName', name);
		}, 
		setDealId({ commit }, id){
			commit('setDealId', id);
		},
		async loadList({ commit }){
			let data = await requests.loadListCalc();		 
			commit('setList', data);
		}, 
		async loadData({ commit }){
			let data = await requests.loadDataCalc();		 
			commit('setTables', data.data);

			let today = new Date();
			let dd = String(today.getDate()).padStart(2, '0');
			let mm = String(today.getMonth() + 1).padStart(2, '0');  
			let yyyy = today.getFullYear();

			today = dd + '/' + mm + '/' + yyyy;

			commit('setCurrentDate', today);
		}, 
		addProduct({ commit }){
			commit('setProduct', {
				product:null,
				quantity:1,
				cloth:null,
				height:0,
				width:0,
				fold:1,
				lining:false,
				cloth_lining:null,
				weight:0,
				job_price:0,
				total_product_pg:0,
				total_lining_pg:0,

				total_price:0,
				tax_before:0,
				tax_after:0, 
				koef_weight_1:0,
				koef_weight_block_product_1:0, 

				view_total_price_1:0,
				view_total_price_all:0,
			});
		},
		async delProduct({ commit, dispatch }, key){  
			commit('spliceProduct', key);  
			commit('setWeight', { key:null, weight:null }); 
			commit('setTotalPrice'); 
		},
		addMechanics({ commit }){
			commit('setMechanics', {
				mechanic:null,
				product:null,
				product_key:null,
				length:0,
				height:0,
				weight:0,
				quantity:1, 
				
				total:0,
				tax_before:0,
				tax_after:0,
				koef_weight_1:0, 
				koef_weight_block_mechanics_1:0,

				view_total_price_1:0,
				view_total_price_all:0,
			});
		},
		async delMechanics({ commit, dispatch }, key){
			commit('spliceMechanics', key); 
			commit('setTotalPrice');  
		},
		selectTax({ commit }, event){  
			commit('setTax', event.target.id);  
			commit('setTotalPrice');
		},
		mechanicsNull({ commit }){
			commit('setMechanics', null); 
		},
		async changeMechanic({ commit, state, dispatch }, key){
			let total_mechanic = 0;
			let quantity = state.calculations.mechanics[key].quantity; 
			 
			if(state.calculations.mechanics[key].mechanic.type !== 'profile') 
				//total_mechanic = Number(state.calculations.mechanics[key].mechanic.price)*Number(quantity); 
				total_mechanic = Number(state.calculations.mechanics[key].mechanic.price); 						 
 
			commit('setTotalMechanic', { key, total_mechanic });  
			commit('setTotalPrice');
		}, 
		async changeProduct({ commit, state, dispatch }, key){ 

			let product_pg = null;
			let lining_pg = null;
 
			//проверка механики 

			if(state.calculations.products !== null && state.calculations.mechanics !== null) 
				for(let key_m in state.calculations.mechanics) 
					if(state.calculations.mechanics[key_m].product !== null){					
						let mechanics_nullable = true;
						let product_id = state.calculations.mechanics[key_m].product.product.id;
						for(let key_p in state.calculations.products){
							if(product_id == state.calculations.products[key_p].product.id) {
								mechanics_nullable = false;
								await dispatch('changeMechanic', key_m)
							}
						}  
						if(mechanics_nullable) commit('mechanicNullable', key_m); 
					}
				  
			// вычисление погонных метров лицевой части
			if(
				state.calculations.products[key].product !== null && 
				state.calculations.products[key].cloth !== null &&
				state.calculations.products[key].width !== 0 &&
				state.calculations.products[key].height !== 0 &&
				state.calculations.products[key].fold !== 0 &&
				state.calculations.products[key].quantity !== 0 
			) {
				let roll_width = Number(state.calculations.products[key].cloth.roll_width);
				await dispatch("calcPgProduct", {key, roll_width, type:'product'}).then((total_product_pg)=>{ 
					product_pg = total_product_pg; 
					commit('setTotalProductPg', { key, total_product_pg }); 
				}); 
			}
			else commit('setTotalProductPg', { key, total_product_pg:0 });
			
			// вычисление погонных метров подкладки
			if(
				state.calculations.products[key].product !== null && 
				state.calculations.products[key].lining &&
				state.calculations.products[key].cloth_lining !== null &&
				state.calculations.products[key].width !== 0 &&
				state.calculations.products[key].height !== 0 &&
				state.calculations.products[key].fold !== 0 &&
				state.calculations.products[key].quantity !== 0 
			) {
				let roll_width = Number(state.calculations.products[key].cloth_lining.roll_width);
				await dispatch("calcPgProduct", {key, roll_width, type:'lining'}).then((total_lining_pg)=>{ 
					lining_pg = total_lining_pg; 
					commit('setTotalLiningPg', { key, total_lining_pg }); 
				}); 
			}
			else commit('setTotalLiningPg', { key, total_lining_pg:0 });
			
			// вычисление веса
			if(
				state.calculations.products[key].product !== null &&  
				state.calculations.products[key].cloth !== null &&
				state.calculations.products[key].width !== 0 &&
				state.calculations.products[key].height !== 0 && 
				state.calculations.products[key].quantity !== 0 
			)  dispatch("calcWeightProduct", key);  

			// вычисление стоимости материала
			let total_price = 0;  
			if(product_pg !== null) total_price = product_pg*state.calculations.products[key].cloth.price_pm; 
			if(lining_pg !== null) total_price = total_price + lining_pg*state.calculations.products[key].cloth_lining.price_pm;
			
		 
			commit('setTotalProductPrice', { key, total_price });   

			// вычисление стоимости работы и себестоимости изделия
			
			if(
				state.calculations.products[key].product !== null &&  
				state.calculations.products[key].width !== 0 &&
				state.calculations.products[key].height !== 0 &&
				state.calculations.products[key].fold !== 0 &&
				state.calculations.products[key].quantity !== 0 
			) {
				let width = Number(state.calculations.products[key].width);
				let height = Number(state.calculations.products[key].height);
				let fold = Number(state.calculations.products[key].fold);
				let quantity = Number(state.calculations.products[key].quantity);
				let job_price = Number(state.calculations.products[key].product.job_price);
				let total_price = Number(state.calculations.products[key].total_price); 
				let job_price_total = height*width*fold*quantity*job_price;
				if(state.calculations.products[key].lining && state.calculations.products[key].cloth_lining !== null) job_price_total = 2*job_price_total; 
				total_price = Number(total_price) + Number(job_price_total); 

				commit('setJobProductPrice', { key, job_price_total });  
				commit('setTotalProductPrice', { key, total_price }); 
			} 		  
			////////////////  
			commit('setTotalPrice');  
		},
		async calcWeightProduct({ state, commit }, key){ 

			let width = Number(state.calculations.products[key].width);
			let height = Number(state.calculations.products[key].height);
			let fold = Number(state.calculations.products[key].fold);
			let quantity = Number(state.calculations.products[key].quantity);
			let fabric_density = Number(state.calculations.products[key].cloth.fabric_density);
			let weight = width*height*quantity*fold*fabric_density/1000;

			if(state.calculations.products[key].lining && state.calculations.products[key].cloth_lining !== null){
				
				let fabric_density = Number(state.calculations.products[key].cloth_lining.fabric_density);
				weight = weight + (width*height*quantity*fold*fabric_density/1000);
				weight = Math.ceil(weight);
			}
			
			commit('setWeight', { key, weight });
		},
		async calcPgProduct({ state }, { key, roll_width, type}){ 
 
				let width = Number(state.calculations.products[key].width);
				let height = Number(state.calculations.products[key].height);
				let fold = Number(state.calculations.products[key].fold);
				let quantity = Number(state.calculations.products[key].quantity);
				  
				let total_pg = (width*fold*quantity)/roll_width;
 
				if(Number.isInteger(total_pg)) total_pg = total_pg + 1;
				else total_pg = Math.ceil(total_pg);

				if(state.calculations.products[key].product.id == 2) {//"Арлекин фигурный"
					if(type == 'product' || type == 'lining')total_pg = total_pg*(2*height+(2*height*3/100)) 
				}
				else if(state.calculations.products[key].product.id == 7) {//"Задник французский"
					if(type == 'product')total_pg = total_pg*(2*height+(2*height*3/100)) 
				}
				else if(state.calculations.products[key].product.id == 8) {//"Задник австрийский"
					if(type == 'product')total_pg = total_pg*(1.3*height+(1.3*height*3/100)) 
				}
				else { 
					if(height <= 3.3) height = height + 0.15;
					if(height > 3.3 && height <= 4.5) height = height + 0.2;
					if(height >= 4.5) height = height + 0.25; 
					total_pg = total_pg*(height+(height*3/100));  
				}			 
 
				return total_pg; 
		},  
		changeDelivery({ commit}, value){ 
			if(value !== null) commit('setDeliveryVal', value);
			commit('setTotalPrice');
		},
		changeMontage({ commit, state }, value){  	

			if(value !== null) commit('setMontageVal', value);
			 
			commit('setTotalPrice');
		}, 
		changeAddress({ commit }, value){  				 
			commit('setAddress', value);
		},
		setDeleveryAddress({ state, commit, rootGetters }){
			
			let address = null;

			if(state.calculations.delivery.status_delline){
   
				if(rootGetters["delLine/delLine"].city_selected == null && 
				rootGetters["delLine/delLine"].street_selected == null && 
				rootGetters["delLine/delLine"].home_selected== null)
				address = state.calculations.delivery.rec_address;
				else address = rootGetters["delLine/delLine"].city_selected.aString+", "+
				rootGetters["delLine/delLine"].street_selected.aString+", "+
				rootGetters["delLine/delLine"].home_selected;
	
				commit('setAddress', address);
			}
		}  
	}
}